/** @jsx jsx */
import { GatsbyImage } from 'gatsby-plugin-image'
import { Grid, jsx, Flex, useThemeUI } from 'theme-ui'

import ImageAndTextSection from '../Product/Page/ImageAndTextSection'
import Heading from '~/components/IntlComponents/Heading'

import Text from '~/components/IntlComponents/Text'
import BouncyIngredientIcon from '~/components/Home/BouncyIngredientIcon'
import StyledWrapper from '~/components/Generic/StyledWrapper'

function NoodleBowlSection({ page: { heroImage }, activeHero }) {
  const {
    mainImage,
    title,
    secondTitle,
    description,
    backgroundColor,
    textColor,
    media,
  } = activeHero.noodleBowlSection

  const noodleBowlHeading = title
  const noodleBowlSecondHeading = secondTitle
  const noodleBowlSubheading = description?.description
  const bowlImage = mainImage
  const valuePropImages = media[0]

  const { theme } = useThemeUI()
  //slice array of bouncing Icons
  const leftBouncingIcons = valuePropImages?.valuePropImage.slice(0, 3)
  const rightBouncingIcons = valuePropImages?.valuePropImage.slice(3, 6)
  return (
    <Flex
      sx={{
        backgroundColor: backgroundColor || theme.colors.frenchPass,
        position: 'relative',
        textAlign: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        color: textColor || theme.colors.governorBay,
        px: [0, 50],
        pb: ['100px', null, '192px'],
      }}
    >
      <ImageAndTextSection
        cta={activeHero.heroCallToAction}
        fluid={heroImage.fluid}
        headingText={activeHero?.heading?.heading}
        subHeadingText={activeHero?.subheading?.subheading}
        containerStyle={{
          display: ['flex', 'none'],
          mt: ['0', '127px'],
          mb: ['22px'],
          my: 'auto',
          maxWidth: '37.5rem',
          width: '85vw',
          zIndex: 2,
          justifyContent: 'center',
        }}
        headingForwardSx={{
          fontSize: ['3rem', '1rem', '1rem'],
        }}
        subHeadingForwardSx={{
          fontSize: ['1rem', '1rem', '2.5rem'],
          fontWeight: 800,
        }}
        buttonStyle={{
          width: '100%',
          maxWidth: '320px',
          mt: '1rem',
          color: '#FFEE00',
          backgroundColor: 'override.press.button.back',
          ':hover': {
            color: 'override.press.button.hover.color',
            backgroundColor: '#FFEE00',
          },
        }}
      />
      <StyledWrapper>
        <Heading
          sx={{
            width: ['85%', 'auto'],
            maxWidth: 800,
            pt: 96,
            mx: 'auto',
          }}
          variant="text.h2"
        >
          {noodleBowlHeading}
          <br />
          {noodleBowlSecondHeading}
        </Heading>
      </StyledWrapper>
      <StyledWrapper>
        <Text
          as="p"
          sx={{
            width: ['85%', 'auto'],
            maxWidth: 680,
            marginBottom: 60,
            fontSize: 18,
            px: '10px',
            mx: 'auto',
            fontWeight: 900,
          }}
        >
          {noodleBowlSubheading}
        </Text>
      </StyledWrapper>
      <Grid
        columns={[
          1,
          ' minmax(120px,200px)  minmax(0px,700px) minmax(120px,200px)',
        ]}
      >
        <Grid
          columns={[3, 1]}
          sx={{
            width: ['90%', '100%'],
            maxWidth: ['100%', 200],
            justifySelf: ['center', 'end'],
          }}
        >
          {leftBouncingIcons.map((imgObj, i) => {
            return (
              <BouncyIngredientIcon
                justifySelf={i === 1 ? 'start' : 'end'}
                imgObj={imgObj}
                forwardSx={{ width: ['auto', 120], height: ['150px', 'auto'] }}
                key={i}
              />
            )
          })}
        </Grid>
        {/* This container lets us do an overflow of the noodlebowl */}
        <div
          sx={{
            width: ['85%', '100%'],
            justifySelf: 'center',
          }}
        >
          <GatsbyImage
            image={bowlImage.gatsbyImageData}
            alt={bowlImage.title}
          />
        </div>
        <Grid
          columns={[3, 1]}
          sx={{
            width: ['90%', '100%'],
            maxWidth: ['100%', 200],
            justifySelf: ['center'],
          }}
        >
          {rightBouncingIcons.map((imgObj, i) => {
            return (
              <BouncyIngredientIcon
                justifySelf={i === 1 ? 'end' : 'start'}
                imgObj={imgObj}
                forwardSx={{ width: ['auto', 120], height: ['150px', 'auto'] }}
                key={i}
              />
            )
          })}
        </Grid>
      </Grid>
    </Flex>
  )
}

export default NoodleBowlSection
